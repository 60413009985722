.ant-menu-item-selected,
.ant-menu-submenu-selected > .ant-menu-submenu-title {
  background-color: transparent !important;
  color: #fa8c15 !important;
}
.sidebar-height {
  height: calc(100vh - 146px) !important;
}
.ant-layout .ant-layout-sider,
.ant-menu-dark {
  background-color: #003366 !important;
}
.ant-menu-inline {
  background-color: transparent !important;
}
.primary-box-shadow {
  box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.06);
}
.primary-button {
  background-color: #fadb14;
}
.ant-picker-ok > button {
  background-color: #1677ff;
}
.ant-btn-primary {
  background-color: #1677ff !important;
}

.ant-tabs-tab {
  width: 508px;
  height: 60px;
  font-size: 25px;
  font-weight: bold;
}

.custom-tab-pane {
  width: 800px;
}

.ant-table .ant-table-container .ant-table-content table thead.ant-table-thead .ant-table-cell {
  background-color: #eff4fa;
}

button {
  outline: none;
}
